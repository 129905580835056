import React from "react";
import NcImage from "components/NcImage/NcImage";
import Layout from "app/(singles)/(default)/layout";
import SingleTitle from "app/(singles)/SingleTitle";
import axios from 'axios'
import {useState, useEffect ,useRef }  from "react";
import { useParams } from "react-router";
import { AlittihadFeatures } from "data/types";
import PostMeta2 from "components/PostMeta2/PostMeta2";
import SingleMetaAction2 from "app/(singles)/SingleMetaAction2";
import { FC } from "react";
import Tag from "components/Tag/Tag";
import SingleAuthor from "app/(singles)/SingleAuthor";
import SingleCommentForm from "app/(singles)/SingleCommentForm";
import SingleCommentLists from "app/(singles)/SingleCommentLists";
import SingleContentDemo from "app/(singles)/SingleContentDemo";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import PostCardLikeAction from "components/PostCardLikeAction/PostCardLikeAction";
import PostCardCommentBtn from "components/PostCardCommentBtn/PostCardCommentBtn";
import { ArrowUpIcon } from "@heroicons/react/24/solid";
import { DEMO_TAGS } from "data/taxonomies";
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { Toast } from "@capacitor/toast";
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonFooter, IonList, IonCard, IonCardContent, IonItem, IonLabel, IonListHeader, IonText, IonButtons, IonMenuButton } from '@ionic/react';
import { useNavigate } from "react-router-dom";


const demoTags = DEMO_TAGS.filter((_, i) => i < 9);


export interface SingleHeaderProps {
  hiddenDesc?: boolean;
  titleMainClass?: string;
  className?: string;
}



export interface SingleContentProps {}

const PushAlittihad = () => {
  const navigate = useNavigate();

  const nullEntry: any[] = []
  const params = useParams();
  const [posts, setPost] = useState<AlittihadFeatures[]>([]);
  const [notifications, setnotifications] = useState(nullEntry);

  const url = "https://alittihad44.com/";

  useEffect(() => {
   
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await axios.get(
      "https://alittihad44.com/api/v2/new/" + params.id
    );
    setPost(response.data);

  };

  useEffect(()=>{
      PushNotifications.checkPermissions().then((res) => {
          if (res.receive !== 'granted') {
            PushNotifications.requestPermissions().then((res) => {
              if (res.receive === 'denied') {
                showToast('Push Notification permission denied');
              }
              else {
                showToast('Push Notification permission granted');
                register();
              }
            });
          }
          else {
            register();
          }
        });
  },[])
  
  const register = () => {


      console.log('Initializing HomePage');

      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration',
          (token: Token) => {
              showToast('Push registration success');
          }
      );



      

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
          (error: any) => {
              alert('Error on registration: ' + JSON.stringify(error));
              
          }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
          (notification: PushNotificationSchema) => {
              setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
          }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
          (notification: ActionPerformed) => {
              setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
          }
      );

      
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed)  => {
        const data = notification.notification.data;

        console.log('Action performed: ' + JSON.stringify(notification.notification));
        if (data.id) {
          navigate(`${data.id} + "/" + ${data.cid}`);
        }
      }
    )
      
  }

  const showToast = async (msg: string) => {
      await Toast.show({
          text: msg
      })
  }

  


  const SingleHeader: FC<SingleHeaderProps> = ({
    titleMainClass,
    hiddenDesc = false,
    className = "",
  }) => {


    return (
      <>

 {notifications.length !== 0 &&
      notifications.map((item, index) => (
        <div key={index} className={`nc-SingleHeader ${className}`}>
          <div className="space-y-5">
          {/*   <CategoryBadgeList
              itemClass="!px-3"
             categories={[DEMO_CATEGORIES[1]]} 
            />
            */} 
            <SingleTitle
              mainClass={titleMainClass}
              stitle={item.title}
            />
            {!hiddenDesc && (
              <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
               هل نحن بحاجة لاستمرار الوصف؟   هل نحن بحاجة لاستمرار الوصف؟   هل نحن بحاجة لاستمرار الوصف؟   هل نحن بحاجة لاستمرار الوصف؟ 
              </span>
            )}
            <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0 sm:space-x-5">
              <PostMeta2
                size="large"
                className="leading-none flex-shrink-0"
                hiddenCategories
                avatarRounded="rounded-full shadow-inner"
              />
              <SingleMetaAction2 />
            </div>
          </div>
        </div>
            ))}
  
          
      </>
    );
  };

  const SingleContent: FC<SingleContentProps>  = () => {
    const endedAnchorRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const progressRef = useRef<HTMLButtonElement>(null);
    //
    const [isShowScrollToTop, setIsShowScrollToTop] = useState<boolean>(false);
    //
  
    const endedAnchorEntry = useIntersectionObserver(endedAnchorRef, {
      threshold: 0,
      root: null,
      rootMargin: "0%",
      freezeOnceVisible: false,
    });
  
    useEffect(() => {
      const handleProgressIndicator = () => {
        const entryContent = contentRef.current;
        const progressBarContent = progressRef.current;
  
        if (!entryContent || !progressBarContent) {
          return;
        }
  
        const totalEntryH = entryContent.offsetTop + entryContent.offsetHeight;
        let winScroll =
          document.body.scrollTop || document.documentElement.scrollTop;
        let scrolled = (winScroll / totalEntryH) * 100;
  
        progressBarContent.innerText = scrolled.toFixed(0) + "%";
  
        if (scrolled >= 100) {
          setIsShowScrollToTop(true);
        } else {
          setIsShowScrollToTop(false);
        }
      };
  
      const handleProgressIndicatorHeadeEvent = () => {
        window?.requestAnimationFrame(handleProgressIndicator);
      };
      handleProgressIndicator();
      window?.addEventListener("scroll", handleProgressIndicatorHeadeEvent);
      return () => {
        window?.removeEventListener("scroll", handleProgressIndicatorHeadeEvent);
      };
    }, []);
  
    const showLikeAndCommentSticky =
      !endedAnchorEntry?.intersectionRatio &&
      (endedAnchorEntry?.boundingClientRect.top || 0) > 0;
  
    return (
      <div className="relative">
{notifications.length !== 0 && 

notifications.map((item, index) => ( 
  <div key={index} className="nc-SingleContent space-y-10">
    {/* ENTRY CONTENT */}
    <div dangerouslySetInnerHTML={{__html: item.article}}
      id="single-entry-content"
      className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert"
      ref={contentRef}
    >
   
    </div>

    {/* TAGS */}
    <div className="max-w-screen-md mx-auto flex flex-wrap">
      {demoTags.map((item) => (
        <Tag hideCount key={item.id} tag={item} className="mr-2 mb-2" />
      ))}
    </div>

    {/* AUTHOR */}
    <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
    <div className="max-w-screen-md mx-auto ">
      <SingleAuthor />
    </div>

    {/* COMMENT FORM */}
    <div
      id="comments"
      className="scroll-mt-20 max-w-screen-md mx-auto pt-5"
    >
      <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
        Responses (10)
      </h3>
      <SingleCommentForm />
    </div>

    {/* COMMENTS LIST */}
    <div className="max-w-screen-md mx-auto">
      <SingleCommentLists />
      <div ref={endedAnchorRef}></div>
    </div>
  </div>
))}


        <div
          className={`sticky mt-8 bottom-8 z-40 justify-center ${
            showLikeAndCommentSticky ? "flex" : "hidden"
          }`}
        >
          <div className="bg-white dark:bg-neutral-800 shadow-lg rounded-full ring-1 ring-offset-1 ring-neutral-900/5 p-1.5 flex items-center justify-center space-x-2 text-xs">
            <PostCardLikeAction className="px-3 h-9 text-xs" />
            <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>
            <PostCardCommentBtn
              isATagOnSingle
              className={` flex px-3 h-9 text-xs`}
            />
            <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>
  
            <button
              className={`w-9 h-9 items-center justify-center bg-neutral-50 dark:bg-neutral-800 hover:bg-neutral-100 rounded-full ${
                isShowScrollToTop ? "flex" : "hidden"
              }`}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <ArrowUpIcon className="w-4 h-4" />
            </button>
  
            <button
              ref={progressRef}
              className={`w-9 h-9 items-center justify-center ${
                isShowScrollToTop ? "hidden" : "flex"
              }`}
              title="Go to top"
            >
              %
            </button>
          </div>
        </div>
     
      </div>
    
    );
  };


  return (
    <IonPage id='main'>
    <IonHeader>
        <IonToolbar color="primary">
            <IonTitle slot="start"> Push Notifications</IonTitle>
        </IonToolbar>
        <IonToolbar color="light">
            <IonTitle slot="start">By Enappd Team</IonTitle>
        </IonToolbar>
    </IonHeader>
    <IonContent className="ion-padding">
        <div>
            <IonList>
                <IonCard>
                    <IonCardContent>
                        1. Register for Push by clicking the footer button.<br></br>
                        2. Once registered, you can send push from the Firebase console. <br></br>
                        <a href="https://enappd.gitbook.io/ionic-5-react-capacitor-full-app/features/push-notifications" target="_blank">Check documentation</a><br></br>
                        3. Once your app receives notifications, you'll see the data here in the list
                    </IonCardContent>
                </IonCard>
            </IonList>

        </div>
        <IonListHeader mode="ios" lines="full">
            <IonLabel>Notifications</IonLabel>
        </IonListHeader>
        {notifications.length !== 0 &&
            <IonList>

                {notifications.map((notif: any) =>
                    <IonItem key={notif.id}>
                        <IonLabel>
                            <IonText>
                                <h3 className="notif-title">{notif.title}</h3>
                            </IonText>
                            <p>{notif.body}</p>
                            {notif.type==='foreground' && <p>This data was received in foreground</p>}
                            {notif.type==='action' && <p>This data was received on tap</p>}
                        </IonLabel>
                    </IonItem>
                )}
            </IonList>}
    </IonContent>
    <IonFooter>
        <IonToolbar>
            <IonButton color="success" expand="full" onClick={register}>الموافقة على تلقي الإشعار  </IonButton>
        </IonToolbar>
    </IonFooter>
</IonPage >
  );
};

export default PushAlittihad;
