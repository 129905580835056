import React, { FC } from "react";
import PostCardMeta from "components/PostCardMeta/PostCardMeta";
import PostCardSaveAction from "components/PostCardSaveAction/PostCardSaveAction";
import { AlittihadFeatures } from "data/types";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import Badge from "components/Badge/Badge";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import Link from "components/Link";
import Image from "components/Image";

export interface Card6Props {
  className?: string;
  post: AlittihadFeatures;
}

const Card6: FC<Card6Props> = ({ className = "h-full", post }) => {
  const { stitle, slug, ctitle ,slugimage, categorie_id, curl,postType, id } =
    post;

  return (
    <div
      className={`nc-Card6 relative flex group flex-row items-center sm:p-4 sm:rounded-3xl sm:bg-white sm:dark:bg-neutral-900 sm:border border-neutral-200 dark:border-neutral-700 ${className}`}
    >
      <Link href={id + '/' +slug} className="absolute inset-0 z-0"></Link>
      <div className="flex flex-col flex-grow">
        <div className="space-y-3 mb-4">
         <div className={`nc-CategoryBadgeList flex flex-wrap space-x-2`}>
         <Badge
          key={id}
          name={ctitle}
          href={curl}
          color={'red'}
        />
         </div>
          <h2 className={`block font-semibold text-sm sm:text-base`}>
            <Link href={id + '/' + slug} className="line-clamp-2" title={stitle}>
              {stitle}
            </Link>
          </h2>
       <PostCardMeta />
        </div>
        <div className="flex items-center flex-wrap justify-between mt-auto">
         {/*  <PostCardSaveAction className="relative" readingTime={readingTime} /> */}
        </div>
      </div>

      <Link
        href={id + '/' +slug}
        className={`block relative flex-shrink-0 w-24 h-24 sm:w-40 sm:h-full ml-3 sm:ml-5 rounded-2xl overflow-hidden z-0`}
      >
        <Image
          sizes="(max-width: 600px) 180px, 400px"
          className="object-cover w-full h-full"
          fill
          src={'https://alittihad44.com/' + slugimage}
          alt={stitle}
        />
        <span className="absolute bottom-1 left-1">
          <PostTypeFeaturedIcon
            wrapSize="h-7 w-7"
            iconSize="h-4 w-4"
            postType={postType}
          />
        </span>
      </Link>
    </div>
  );
};

export default Card6;
