import Cardbreaking from "components/cardbreaking/cardbreaking";
import WidgetHeading1 from "components/WidgetHeading1/WidgetHeading1";
import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React, { FC, useState, useEffect } from "react";
import { AlittihadFeatures } from "data/types";
import axios from 'axios'

const widgetPostsDemo: PostDataType[] = DEMO_POSTS.filter(
  (_, i) => i > 2 && i < 7
);

export interface WidgetPostsProps {
  className?: string;
  posts?: AlittihadFeatures[];
}

const WidgetPosts: FC<WidgetPostsProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",

}) => {

  const [posts, setLatest] = useState<AlittihadFeatures[]>([]);

  useEffect(() => {
   
    fetchData();
  }, []);


    const fetchData = async () => {
      const response = await axios.get(
        "https://alittihad44.com/api/v2/HomePage"
      );
      setLatest(response.data.latest);

   };


  
  return (
    <div className={`nc-WidgetPosts rounded-3xl overflow-hidden ${className}`}>
      <WidgetHeading1
        title="🎯  الأخبار العاجلة "
        viewAll={{ label: "المزيد ", href: "/breaking" }}
      />
      <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
        {posts.filter((_, i) => i >= 1 && i < 7).map((post) => (
          <Cardbreaking
            className="p-4 xl:px-5 xl:py-6 hover:bg-neutral-200 dark:hover:bg-neutral-700"
            key={post.id}
            post={post}
          />
        ))}
      </div>
    </div>
  );
};

export default WidgetPosts;
