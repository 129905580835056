import React, { FC } from "react";
import { AlittihadFeatures } from "data/types";
import CardAuthor2 from "components/CardAuthor2/CardAuthor2";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import Link from "components/Link";
import { DEMO_AUTHORS } from "data/authors";
import Badge from "components/Badge/Badge";

export interface Card5Props {
  className?: string;
  post: AlittihadFeatures;
}

const Card5: FC<Card5Props> = ({ className = "", post }) => {
  const { stitle, slug, slugimage, categorie_id, postType, id, ctitle , curl ,updated_at  } = post;
  return (
    <div
      className={`nc-Card5 relative p-5 group border border-neutral-200 hover:shadow-lg transition-shadow dark:border-neutral-700 rounded-3xl bg-white dark:bg-neutral-900 ${className}`}
    >
      <Link href={id+"/"+slug} className="absolute inset-0 rounded-lg"></Link>

      <div className="flex flex-col">
      <div
      className={`nc-CategoryBadgeList ${className}`}
      data-nc-id="CategoryBadgeList"
    >
      
        <Badge
          key={id}
          name={ctitle}
          href={curl}
        />
    
    </div>
        <h2
          className="block text-base font-semibold text-neutral-800 dark:text-neutral-300 my-4"
          title={stitle}
        >
          <Link href={id+"/"+slug} className="line-clamp-2" title={stitle}>
            {stitle}
          </Link>
        </h2>
        <CardAuthor2
          className="relative mt-auto"
          author={DEMO_AUTHORS[1]}
          date={updated_at}
        />
      </div>
    </div>
  );
};

export default Card5;
