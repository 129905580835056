
import axios from 'axios'
import { AlittihadFeatures } from "./types";
import { AlittihadCategories } from "./types";

import { DEMO_CATEGORIES } from "./taxonomies";
import { DEMO_AUTHORS } from "./authors";
import React, { FC, useState, useEffect } from "react";

export default function GetCats() {

  const [NewsCategories, setCat] = useState<AlittihadCategories[]>([]);
  const [News, setNews] = useState<AlittihadFeatures[]>([]);


  useEffect(() => {
   
    fetchData();
  }, []);
    const fetchData = async () => {
      const Cat = await axios.get(
        "https://alittihad44.com/api/v2/categories"
      );
      const News = await axios.get(
        "https://alittihad44.com/api/v2/category/" + 1
      );

      console.log("response", News);
      setNews(News.data.data)
      setCat(Cat.data);



    };

 
    
    return  NewsCategories
     

}



