import GetCats from "data/cats";
import React, { FC } from "react";
import Badge from "components/Badge/Badge";
import { AlittihadFeatures } from "data/types";

export interface CategoryBadgeListProps {
  className?: string;
  itemClass?: string;

}

const CategoryBadgeList: FC<CategoryBadgeListProps> = ({
  className = "flex flex-wrap space-x-2",
  itemClass,
}) => {
  return (
    <div
      className={`nc-CategoryBadgeList ${className}`}
      data-nc-id="CategoryBadgeList"
    >
      {GetCats().map((item, index) => (
        <Badge
          className={itemClass}
          key={index}
          name={item.ctitle}
          href={item.curl}
          color={item.color as any}
        />
      ))}
    </div>
  );
};

export default CategoryBadgeList;
