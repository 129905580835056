import CardCategory1 from "components/CardCategory1/CardCategory1";
import WidgetHeading1 from "components/WidgetHeading1/WidgetHeading1";
import GetCats from "data/cats";
import { AlittihadCategories } from "data/types";
import React, { FC } from "react";
import { useState, useEffect } from "react";
import axios from 'axios'


export interface WidgetCategoriesProps {
  className?: string;
  categories?: AlittihadCategories[];
}

const WidgetCategories: FC<WidgetCategoriesProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
}) => {
  const [NewsCategories, setCat] = useState<AlittihadCategories[]>([]);


  useEffect(() => {
   
    fetchData();
  }, []);
    const fetchData = async () => {
      const Cat = await axios.get(
        "https://alittihad44.com/api/v2/categories"
      );
      const News = await axios.get(
        "https://alittihad44.com/api/v2/category/" + 1
      );

      setCat(Cat.data);

    };
  return (
    <div
      className={`nc-WidgetCategories rounded-3xl  overflow-hidden ${className}`}
    >
      <WidgetHeading1
        title="✨ Trending topic"
        viewAll={{ label: "View all", href: "/#" }}
      />
      <div className="flow-root">
        <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
          {NewsCategories.filter((_, i) => i > 7 && i < 13).map((category) => (
            <CardCategory1
              className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700"
              key={category.id}
              taxonomy={category}
              size="normal"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WidgetCategories;
