import React, { ReactNode } from "react";
import SingleContent from "../SingleContent";
import SingleRelatedPosts from "../SingleRelatedPosts";


const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <div>
      {children}

     

      {/* RELATED POSTS */}
      <SingleRelatedPosts />
    </div>
  );
};

export default Layout;
