import CardCategory1 from "components/CardCategory1/CardCategory1";
import CardCategory2 from "components/CardCategory2/CardCategory2";
import Heading from "components/Heading/Heading";
import GetCats from "data/cats";
import { AlittihadCategories } from "data/types";
import React from "react";
import {useState, useEffect } from "react";
import axios from 'axios'


export interface SectionGridCategoryBoxProps {
  categories?: AlittihadCategories[];
  headingCenter?: boolean;
  categoryCardType?: "card1" | "card2" | "card3" | "card4" | "card5";
  className?: string;
}


const SectionGridCategoryBox: React.FC<SectionGridCategoryBoxProps> = ({
  categoryCardType = "card2",
  headingCenter = true,
  className = "",
}) => {

  const [categories, setCat] = useState<AlittihadCategories[]>([]);


  useEffect(() => {
   
    fetchData();
  }, []);
    const fetchData = async () => {
      const Cat = await axios.get(
        "https://alittihad44.com/api/v2/categories"
      );

      setCat(Cat.data);

    };



  let CardComponentName = CardCategory2;
  switch (categoryCardType) {
    case "card1":
      CardComponentName = CardCategory1;
      break;
    case "card2":
      CardComponentName = CardCategory2;
      break;
 

    default:
      CardComponentName = CardCategory2;
  }

  return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      <Heading desc="Discover over 100 topics" isCenter={headingCenter}>
        Top trending topics
      </Heading>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 sm:gap-6 md:gap-8">
        {categories.filter((_, i) => i < 10).map((item, i) => (
          <CardComponentName
            index={i < 3 ? `#${i + 1}` : undefined}
            key={item.id}
            taxonomy={item}
          />
        ))}
      </div>
    </div>
  );
};

export default SectionGridCategoryBox;
