import React, {useState, useEffect} from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import {
  DEMO_POSTS_AUDIO,
  DEMO_POSTS_VIDEO,
} from "data/posts";
import { DEMO_AUTHORS } from "data/authors";
import SectionMagazine1 from "components/Sections/SectionMagazine1";
import SectionAds from "components/Sections/SectionAds";
import SectionGridPosts from "components/Sections/SectionGridPosts";
import SectionMagazine8 from "components/Sections/SectionMagazine8";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionCategory from "components/Sections/SectionCategory";
import SectionTrending from "components/Sections/SectionTrending";
import CardLarge1 from "components/CardLarge1/CardLarge1";
import GetHomePage from "data/home";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { Toast } from "@capacitor/toast";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { AlittihadFeatures } from "data/types";
import axios from 'axios'







const PageHome = () => {

  const Register = () => {

    const Navigate = useNavigate();

    const nullEntry: any[] = []
    const params = useParams();
    const [posts, setPost] = useState<AlittihadFeatures[]>([]);
    const [notifications, setnotifications] = useState(nullEntry);
  
    const url = "https://alittihad44.com/";
  
    useEffect(() => {
     
      fetchData();
    }, []);
  
    const fetchData = async () => {
      const response = await axios.get(
        "https://alittihad44.com/api/v2/new/" + params.id
      );
      setPost(response.data);
  
    };
  
    useEffect(()=>{
        PushNotifications.checkPermissions().then((res) => {
            if (res.receive !== 'granted') {
              PushNotifications.requestPermissions().then((res) => {
                if (res.receive === 'denied') {
                  showToast('Push Notification permission denied');
                }
                else {
                  showToast('Push Notification permission granted');
                  Register();
                }
              });
            }
            else {
              Register();
            }
          });
    },[])
    const showToast = async (msg: string) => {
      await Toast.show({
          text: msg
      })
  }


    console.log('Initializing HomePage');

    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
        (token: Token) => {
            showToast('Push registration success');
        }
    );



    

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
        (error: any) => {
            alert('Error on registration: ' + JSON.stringify(error));
            
        }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
            setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
        }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
            setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
        }
    );

    
  PushNotifications.addListener(
    'pushNotificationActionPerformed',
    async (notification: ActionPerformed)  => {
      const data = notification.notification.data;

      console.log('Action performed: ' + JSON.stringify(notification.notification));
      if (data.id) {
        Navigate(`${data.id} + "/" + ${data.cid}`);
      }
    }
  )
    
}

  
  return (
    <div className="nc-PageHome relative ">
      <div className="container relative ">
       
       
      
        {/*       الخبر الرئيسي   */}

       <div className={'nc-SectionLargeSlider relative pt-4 pb-4 md:py-4 lg:pb-4 lg:pt-4'}>
       {GetHomePage().features.filter(
       (p, i) => i ==0 ).map((item, index) => {
        return (
          <CardLarge1
            key={index}
            post={item}
          />
        );
      })}
    </div>


     {/* الأخبار الثانوية   */}
      <SectionMagazine1 className="pb-4" posts={GetHomePage().main} />


         {/*  مقالات مختارة   */}
      <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewAuthors
            heading="مقالات مختارة "
            subHeading=""
            authors={GetHomePage().latestmulhaq.filter((_, i) => i < 6)}
          />
        </div>



     {/*  مقالات رئيسية  
        <SectionTrending 
              className="py-4 lg:py-4"
              posts={GetHomePage().mainmulhaq.filter(
                (p, i) =>  i < 4)}
            />
        <SectionAds 
        
        
        />
 */}
     

       {/*  الزوايا   */}    
        <SectionCategory  className="py-4 lg:py-4" posts={GetHomePage().latest} 
        />

        <SectionTrending
              heading=" مقالات وآراء"
              className="py-4 lg:py-4"
              posts={GetHomePage().latestmulhaq.filter((_, i) => i < 6)}
             
            />

 {/* 
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderPosts
            postCardName="card10V2"
            heading="Explore latest audio articles"
            subHeading="Click on the icon to enjoy the music or podcast 🎧"
            posts={latest.filter((_, i) => i > 3 && i < 10)}
          />
        </div>

        <SectionMagazine7
          className="py-16 lg:py-28"
          posts={DEMO_POSTS_GALLERY.filter((_, i) => i < 6)}
        />

        */}
      </div>

      <div className="dark bg-neutral-900 dark:bg-black dark:bg-opacity-20 text-neutral-100">
        <div className="relative container">
          <SectionGridPosts
            className="py-4 lg:py-4"
            headingIsCenter
            postCardName="card10V2"
            heading="زاوية الفيديوهات"
            subHeading="قائمة منوعة من الفيديوعات على يوتيوب 🥡"
            posts={DEMO_POSTS_VIDEO.filter((_, i) => i > 5 && i < 12)}
            gridClass="md:grid-cols-2 lg:grid-cols-3"
          />
        </div>
      </div>

      <div className="container ">
        <SectionMagazine8
          className="py-4 lg:py-4"
          posts={DEMO_POSTS_AUDIO.filter((_, i) => i < 6)}
        />
  {/* 
<div className="relative py-16">
          <BackgroundSection />
          <SectionGridAuthorBox
          className="py-4 lg:py-4"
          authors={DEMO_AUTHORS.filter((_, i) => i < 5)}
        />
        </div>

       <SectionSubscribe2 className="pt-16 lg:pt-28" /> */}


      </div>
      
    </div>
  );
};

export default PageHome;
