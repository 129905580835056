import Image from "components/Image";
import React, { FC }  from "react";

export interface SingleContentProps {
  article: string;
  

}

const SingleContent: FC<SingleContentProps> = ({

  article
}) => {
  return (
    <>

    {article}
    </>
  );
};

export default SingleContent;
