import React from "react";
import NcImage from "components/NcImage/NcImage";
import Layout from "../layout";
import SingleTitle from "app/(singles)/SingleTitle";
import axios from 'axios'
import {useState, useEffect ,useRef }  from "react";
import { useParams } from "react-router";
import { AlittihadFeatures } from "data/types";
import PostMeta2 from "components/PostMeta2/PostMeta2";
import SingleMetaAction2 from "app/(singles)/SingleMetaAction2";
import { FC } from "react";
import Tag from "components/Tag/Tag";
import SingleAuthor from "app/(singles)/SingleAuthor";
import SingleCommentForm from "app/(singles)/SingleCommentForm";
import SingleCommentLists from "app/(singles)/SingleCommentLists";
import SingleContentDemo from "app/(singles)/SingleContentDemo";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import PostCardLikeAction from "components/PostCardLikeAction/PostCardLikeAction";
import PostCardCommentBtn from "components/PostCardCommentBtn/PostCardCommentBtn";
import { ArrowUpIcon } from "@heroicons/react/24/solid";
import { DEMO_TAGS } from "data/taxonomies";
import Badge from "components/Badge/Badge";

const demoTags = DEMO_TAGS.filter((_, i) => i < 9);

export interface SingleHeaderProps {
  hiddenDesc?: boolean;
  titleMainClass?: string;
  className?: string;
}

export interface SingleContentProps {}

const PageSingle = () => {

  const params = useParams();
  const [posts, setPost] = useState<AlittihadFeatures[]>([]);

  const url = "https://alittihad44.com/";
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const response = await axios.get(
      "https://alittihad44.com/api/v2/new/" + params.id
    );
    setPost(response.data);
  };


  const SingleHeader: FC<SingleHeaderProps> = ({
    titleMainClass,
    hiddenDesc = false,
    className = "",
  }) => {
    return (
      <>
       {posts.map((item, index) => (
        <div key={index} className={`nc-SingleHeader ${className}`}>
          <div className="space-y-5">
          <div
      className={`nc-CategoryBadgeList flex flex-wrap space-x-2`}
      data-nc-id="CategoryBadgeList"
    >
        <Badge
          key={index}
          name={item.ctitle}
          href={item.curl}
        />
    </div>
            <SingleTitle
              mainClass={titleMainClass}
              stitle={item.stitle}
            />
            {/* 
            {!hiddenDesc && (
              <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
               هل نحن بحاجة لاستمرار الوصف؟   هل نحن بحاجة لاستمرار الوصف؟   هل نحن بحاجة لاستمرار الوصف؟   هل نحن بحاجة لاستمرار الوصف؟ 
              </span>
            )}
           */}   
            <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0 sm:space-x-5">
              <PostMeta2
                size="large"
                className="leading-none flex-shrink-0"
                hiddenCategories
                avatarRounded="rounded-full shadow-inner"
              />
              <SingleMetaAction2 />
            </div>
          </div>
        </div>
            ))}
      </>
    );
  };

  const SingleContent: FC<SingleContentProps>  = () => {
    const endedAnchorRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const progressRef = useRef<HTMLButtonElement>(null);
    //
    const [isShowScrollToTop, setIsShowScrollToTop] = useState<boolean>(false);
    //

    const endedAnchorEntry = useIntersectionObserver(endedAnchorRef, {
      threshold: 0,
      root: null,
      rootMargin: "0%",
      freezeOnceVisible: false,
    });
    useEffect(() => {
      const handleProgressIndicator = () => {
        const entryContent = contentRef.current;
        const progressBarContent = progressRef.current;
  
        if (!entryContent || !progressBarContent) {
          return;
        }
  
        const totalEntryH = entryContent.offsetTop + entryContent.offsetHeight;
        let winScroll =
          document.body.scrollTop || document.documentElement.scrollTop;
        let scrolled = (winScroll / totalEntryH) * 100;
  
        progressBarContent.innerText = scrolled.toFixed(0) + "%";
  
        if (scrolled >= 100) {
          setIsShowScrollToTop(true);
        } else {
          setIsShowScrollToTop(false);
        }
      };
  
      const handleProgressIndicatorHeadeEvent = () => {
        window?.requestAnimationFrame(handleProgressIndicator);
      };
      handleProgressIndicator();
      window?.addEventListener("scroll", handleProgressIndicatorHeadeEvent);
      return () => {
        window?.removeEventListener("scroll", handleProgressIndicatorHeadeEvent);
      };
    }, []);
  
    const showLikeAndCommentSticky =
      !endedAnchorEntry?.intersectionRatio &&
      (endedAnchorEntry?.boundingClientRect.top || 0) > 0;
  
    return (
      <div className="relative">
       {posts.map((item, index) => ( 
        <div key={index} className="nc-SingleContent space-y-10">
          {/* ENTRY CONTENT */}
          <div dangerouslySetInnerHTML={{__html: item.article}}
            id="single-entry-content"
            className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert"
            ref={contentRef}
          >
          </div>
          {/* TAGS */}
          <div className="max-w-screen-md mx-auto flex flex-wrap">
            {demoTags.map((item) => (
              <Tag hideCount key={item.id} tag={item} className="mr-2 mb-2" />
            ))}
          </div>
          {/* AUTHOR */}
          <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
          <div className="max-w-screen-md mx-auto ">
            <SingleAuthor />
          </div>
                  {/* COMMENTS LIST */}
        <div className="max-w-screen-md mx-auto">
          <div ref={endedAnchorRef}></div>
        </div>
        </div>

      ))}
            <div
        className={`sticky mt-8 bottom-8 z-40 justify-center ${
          showLikeAndCommentSticky ? "flex" : "hidden"
        }`}
      >
        <div className="bg-white dark:bg-neutral-800 shadow-lg rounded-full ring-1 ring-offset-1 ring-neutral-900/5 p-1.5 flex items-center justify-center space-x-2 text-xs">
          <PostCardLikeAction className="px-3 h-9 text-xs" />
          <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>
          <PostCardCommentBtn
            isATagOnSingle
            className={` flex px-3 h-9 text-xs`}
          />
          <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>

          <button
            className={`w-9 h-9 items-center justify-center bg-neutral-50 dark:bg-neutral-800 hover:bg-neutral-100 rounded-full ${
              isShowScrollToTop ? "flex" : "hidden"
            }`}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <ArrowUpIcon className="w-4 h-4" />
          </button>

          <button
            ref={progressRef}
            className={`w-9 h-9 items-center justify-center ${
              isShowScrollToTop ? "hidden" : "flex"
            }`}
            title="Go to top"
          >
            %
          </button>
        </div>
      </div>
      </div>
    
    );
  };


  return (
    <Layout>
      {posts.map((item, index) => (
      <div key={index} className={`nc-PageSingle pt-8 lg:pt-16`}>
        <header className="container rounded-xl">
          <div className="max-w-screen-md mx-auto">
            <SingleHeader/>
          </div>
        </header>

        {/* FEATURED IMAGE */}
        <NcImage
          alt="single"
          containerClassName="container my-10 sm:my-12"
          className="w-full rounded-xl"
          src={url + item.slugimage}
          width={1260}
          height={750}
          sizes="(max-width: 1024px) 100vw, 1280px"
        />
      
       {/* SINGLE MAIN CONTENT */}
       <div className="container mt-10">
       <SingleContent/>
              
      </div>

      </div>

            ))}
    </Layout>
  );
};

export default PageSingle;
