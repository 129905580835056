import React, {useState, useEffect  } from "react";
import Logo from "components/Logo/Logo";
import SocialsList1 from "components/SocialsList1/SocialsList1";
import axios from 'axios'
import { Route } from "routers/types";
import { NavItemType } from "components/Navigation/NavigationItem";
import { pages } from "data/navigation";

export interface NavMobileProps {
  data?: NavItemType[];
  mcatData?: NavItemType[];
  ncatData?: NavItemType[];
}

export interface CustomLink {
  label: string;
  curl: Route;
  targetBlank?: boolean;
}

const Footer: React.FC<NavMobileProps> = ({

  data = pages,
}) => {

  const [MulhaqCategories, setMulhaq] = useState<NavItemType[]>([]);
  const [NewsCategories, setNews] = useState<NavItemType[]>([]);
  useEffect(() => {
    fetchData();
  },[]);
    const fetchData = async () => {
      const news = await axios.get(
        "https://alittihad44.com/api/v2/cats"
      );
      const mulhaq = await axios.get(
        "https://alittihad44.com/api/v2/mulhaqcats"
      );
      setMulhaq(mulhaq.data);
      setNews(news.data);
    };

const mcatData = MulhaqCategories;
const ncatData = NewsCategories;


  const renderWidgetMenuItem = (
    menu: NavItemType, index: number
  ) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.ctitle}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.children?.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.curl}
              >
                {item.ctitle}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-16 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div>
        </div>
        {ncatData.map(renderWidgetMenuItem)}
        {mcatData.map(renderWidgetMenuItem)}
        {pages.map(renderWidgetMenuItem)}
      </div>
    </div>
  );
};

export default Footer;
