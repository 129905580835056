import React, { FC } from "react";
import WidgetAuthors from "components/WidgetAuthors/WidgetAuthors";
import WidgetCategories from "components/WidgetCategories/WidgetCategories";
import WidgetPosts from "components/WidgetPosts/WidgetPosts";
import WidgetTags from "components/WidgetTags/WidgetTags";
import { DEMO_AUTHORS } from "data/authors";
import { DEMO_POSTS } from "data/posts";
import { DEMO_CATEGORIES, DEMO_TAGS } from "data/taxonomies";
import { AlittihadFeatures } from "data/types";
import axios from 'axios'
import { useState, useEffect } from "react";

export interface SidebarProps {
  className?: string;
}

const tags = DEMO_TAGS.filter((_, i) => i > 5);
const categories = DEMO_CATEGORIES.filter((_, i) => i > 7 && i < 13);
const authors = DEMO_AUTHORS.filter((_, i) => i < 5);

export const Sidebar: FC<SidebarProps> = ({ className = "space-y-6 " }) => {

  const [posts, setPost] = useState<AlittihadFeatures[]>([]);

  const widgetPosts: AlittihadFeatures[] = posts.filter((_, i) => i > 2 && i < 7);

  useEffect(() => {
   
    fetchData();
  }, []);


    const fetchData = async () => {
      const response = await axios.get(
        "https://alittihad44.com/api/v2/HomePage"
      );
      setPost(response.data.features);



    };

  return (
    <div className={`nc-SingleSidebar ${className}`}>
      <WidgetTags tags={tags} />
      <WidgetCategories />
      <WidgetAuthors authors={authors} />
      <WidgetPosts posts={widgetPosts} />
    </div>
  );
};
