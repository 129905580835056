import React, { FC } from "react";
import PostCardMeta from "components/PostCardMeta/PostCardMeta";
import { AlittihadFeatures } from "data/types";
import Link from "components/Link";
import Image from "components/Image";

export interface Card3SmallProps {
  className?: string;
  post: AlittihadFeatures;
}

const Cardbreaking: FC<Card3SmallProps> = ({ className = "h-full", post }) => {
  const { id, stitle, slug, slugimage, curl } = post;

  return (
    <div
      className={`nc-Card3Small relative flex flex-row justify-between items-center ${className}`}
    >
      <Link href={id + '/'+ slug} className="absolute inset-0" title={stitle}></Link>
      <div className="relative space-y-2">
       {/*  <PostCardMeta meta={{ ...post }} /> */}
        <h2 className="nc-card-title block text-sm sm:text-base font-medium sm:font-semibold text-neutral-900 dark:text-neutral-100">
          <Link href={id + '/'+ slug} className="line-clamp-2" title={stitle}>
            {stitle}
          </Link>
        </h2>
      </div>

    </div>
  );
};

export default Cardbreaking;
