import React, { FC } from "react";
import Card2 from "components/Card2/Card2";
import WidgetPosts from "components/WidgetPosts/WidgetPosts";
import { AlittihadFeatures } from "data/types";
import Card6 from "components/Card6/Card6";
import HeaderFilter from "./HeaderFilter";
import Heading from "components/Heading/Heading";


export interface SectionMagazine1Props {
  posts: AlittihadFeatures[];
  heading?: string;
  className?: string;
}

const SectionMagazine1: FC<SectionMagazine1Props> = ({
  posts,
  heading = "الأخبار الهامة..",
  className = "",
}) => {
  return (
    <div className={`nc-SectionMagazine1 pt-4 ${className}`}>

    <Heading>{heading}</Heading>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 md:gap-8">
        <div className="grid gap-6 md:gap-8">
          {posts
            .filter((_, i) => i == 0)
            .map((item, index) => (
              <Card2 key={index} post={item} />
              
            ))}
        </div>
        <div className="grid gap-6 md:gap-8">
          {posts
            .filter((_, i) => i > 0 && i < 4)
            .map((item, index) => (
              <Card6 key={index} post={item} />
              
            ))}
        </div>
        <div className="grid gap-6 md:gap-8">
        <WidgetPosts />
        </div>
        
      </div>
    </div>
  );
};

export default SectionMagazine1;
