import React, { FC, useState, useEffect  } from "react";
import NavigationItem from "./NavigationItem";
import axios from 'axios'
import { Route } from "routers/types";
import { pages } from "data/navigation";

interface Props {
  className?: string;
}

export interface NavItemType {
  id: string;
  ctitle: string;
  curl: Route;
  targetBlank?: boolean;
  children?: NavItemType[];
  type?: "dropdown" | "megaMenu" | "none";
  isNew?: boolean;
}
export interface NavigationItemProps {
  menuItem: NavItemType;
  }
const Navigation: FC<Props> = ({ className = "flex" }) => {
  const [MulhaqCategories, setMulhaq] = useState<NavItemType[]>([]);
  const [NewsCategories, setNews] = useState<NavItemType[]>([]);
  useEffect(() => {
    fetchData();
  },[]);
    const fetchData = async () => {
      const news = await axios.get(
        "https://alittihad44.com/api/v2/cats"
      );
      const mulhaq = await axios.get(
        "https://alittihad44.com/api/v2/mulhaqcats"
      );
      setMulhaq(mulhaq.data);
      setNews(news.data);
    };


    
  return (
    <ul className={`nc-Navigation items-center ${className}`}>
      {NewsCategories.map((item, index) => (
        <NavigationItem key={index} menuItem={item} />
      ))}
         {MulhaqCategories.map((item, index) => (
        <NavigationItem key={index} menuItem={item} />
      ))}

{pages.map((item, index) => (
        <NavigationItem key={index} menuItem={item} />
      ))}
    </ul>
  );
};

export default Navigation;
