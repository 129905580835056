import { Route } from "routers/types";
import __taxonomies from "./jsons/__taxonomies.json";
import __categories from "./jsons/__categories.json";
import axios from 'axios'
import  {useState, useEffect } from "react";


import { TaxonomyType } from "./types";
import { AlittihadCategories } from "./types";

const DEMO_CATEGORIES: TaxonomyType[] = __taxonomies.map((item) => ({
  ...item,
  taxonomy: "category",
  href: item.href as Route,
}));

const DEMO_TAGS: TaxonomyType[] = __taxonomies.map((item) => ({
  ...item,
  taxonomy: "tag",
  href: item.href as Route,
}));




export { DEMO_CATEGORIES ,DEMO_TAGS };
