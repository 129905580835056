import { NavItemType } from "components/Navigation/NavigationItem";
import { Route } from "routers/types";
import _ from "lodash";

const randomId = _.uniqueId;


export const pages: NavItemType[] = [
  {
    id: randomId(),
    curl: "/archive/demo-slug" as Route,
    ctitle: "أرشيف المقالات",
  },

  {
    id: randomId(),
    curl: "/archive/demo-slug" as Route,
    ctitle: "من نحن",
  },
  {
    id: randomId(),
    curl: "/archive/demo-slug" as Route,
    ctitle: "اتصلوا بنا",
  },

];
